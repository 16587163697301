/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "typeface-poppins"
import "./src/styles/global.css"

export const onClientEntry = () => {
  const script = document.createElement('script');
  script.src = "http://192.168.1.167:8000/chat.js";
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    document.addEventListener('DOMContentLoaded', function() {
      var chatConfig = {
        token: "xMSedATchnAiwWxvspzB",
      };
      initializeChatWidget(chatConfig);
    });
  };
};
